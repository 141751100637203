<script lang="ts">
	import { ResponseMessage } from '$lib/components/forms/v2';

	let formClass = 'flex flex-col gap-4';

	export let useEnhance: boolean = true;
	export { formClass as class };
	export let form;

	let formEl: HTMLElement;

	const { form: formData, message, enhance } = form;
	// console.log('Form message', $message);
</script>

{#if $message?.detail}
	<ResponseMessage message={$message} />
{/if}
{#if useEnhance}
	<form bind:this={formEl} method="POST" use:enhance class={formClass} {...$$restProps}>
		<slot {formEl} />
	</form>
{:else}
	<form bind:this={formEl} method="POST" class={formClass} {...$$restProps}>
		<slot {formEl} />
	</form>
{/if}
