<script lang="ts" context="module">
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	import { type FormPath } from 'sveltekit-superforms';

	// the form object
	type T = Record<string, unknown>;
	// the path/name of the field in the form object
	type U = unknown;
</script>

<script lang="ts" generics="T extends Record<string, unknown>, U extends FormPath<T>">
	import HelpText from './HelpText.svelte';

	import { resolvePath } from '$lib/schemas/utils';
	import { Field, Control } from 'formsnap';
	import { getFieldClasses, ValidationMessage, type FieldType } from '$lib/components/forms/v2';
	import { twMerge } from 'tailwind-merge';
	import type { SuperForm } from 'sveltekit-superforms';

	export let form: SuperForm<T>;
	export let name: U;
	export let type: FieldType = 'text';
	export let hide = false;
	export let slots:
		| {
				left?: unknown;
				right?: unknown;
		  }
		| undefined = {};
	export let description: string | undefined = undefined;

	const { form: formData, errors: formErrors } = form;

	$: fieldValue = resolvePath(name, $formData);
	$: fieldErrors = resolvePath(name, $formErrors);
	$: hasValue = (fieldValue?.toString().length || 0) > 0;
	$: isValid = !fieldErrors;

	let isFocused: boolean = false;
	function handleFocus(e: Event) {
		isFocused = e.type == 'focusin';
	}

	$: fieldClasses = getFieldClasses({ fieldType: type, isValid, isFocused, hasValue, isHidden: hide, name });

	$: {
		if (slots?.right || slots?.left) {
			if (slots?.right) {
				fieldClasses.field += ' pr-10';
				fieldClasses.label += ' mr-10';
			}
			if (slots?.left) {
				fieldClasses.field += ' pl-[44px]';
				fieldClasses.label += ' ml-[44px] pl-0';
			}
			fieldClasses.field = twMerge(fieldClasses.field);
			fieldClasses.label = twMerge(fieldClasses.label);
		}
	}
	export let layoutClasses = 'flex flex-col flex-auto gap-1';
</script>

<Field {form} {name} let:value let:errors let:tainted let:constraints>
	<div class={layoutClasses}>
		<div class={fieldClasses.fieldWrapper} on:focusin={handleFocus} on:focusout={handleFocus}>
			<Control let:attrs>
				<div class="relative">
					<slot {attrs} {fieldClasses} {value} {errors} {tainted} {constraints} />
				</div>
			</Control>
		</div>
		{#if errors.length > 0}
			<ValidationMessage />
		{:else if description}
			<HelpText {description} />
		{/if}
	</div>
</Field>
