<script lang="ts">
	import { FieldErrors } from 'formsnap';
	import { formClasses } from '$lib/components/forms/v2';

	let errorClass = `${formClasses.foundation.fieldGapX} ${formClasses.foundation.textInvalid}`;
</script>

<FieldErrors let:errors let:errorAttrs>
	<div class="ml-4 flex flex-col">
		{#if errors[0]}
			{@const error = errors[0]}
			<small class={errorClass} {...errorAttrs}>{error}</small>
		{/if}
	</div>
</FieldErrors>
