import { splitPath } from "sveltekit-superforms";
export {splitPath}
export function resolvePath(name: string, data: any) {
	const path = [data].concat(splitPath(name));
	const returnPath = path.reduce((acc = data, next) => {
		const key = String(next);
		if (typeof next === 'number' || /^\d+$/.test(key)) acc = acc[next];
		else acc = acc[key];
		return acc;
	}, '');

	return returnPath;
}