<script lang="ts">
	import type { SuperForm } from 'sveltekit-superforms';
	import { Button } from '$lib/components/forms/v2';
	import { FontAwesomeIcon } from '@fortawesome/svelte-fontawesome';
	import { faLoader } from '@awesome.me/kit-065f90d5b1/icons/sharp/solid';
	import { twJoin } from 'tailwind-merge';

	export let form: SuperForm<T>;

	const { delayed } = form;

	const classes = {
		layout: {
			default: 'flex-auto mx-auto px-12 py-4 rounded-md w-full md:w-auto border-2 ring-2 ring-inset transition-all ease-in-out',
			hover: '',
			focus: '',
			active: ''
		},
		text: {
			default: 'text-lg',
			hover: '',
			focus: '',
			active: ''
		},
		color: {
			default: 'bg-tee-blue-default border-tee-blue-default text-white ring-transparent',
			hover: 'hover:ring-white',
			focus: 'focus:ring-white',
			active: 'active:bg-tee-blue-s10 active:border-tee-blue-s10'
		}
	};

	const buttonClass = twJoin(Object.values(classes.layout), Object.values(classes.text), Object.values(classes.color));
</script>

<Button type="submit" class={buttonClass} disabled={$delayed ? true : false}>
	{#if $delayed}
		<icon class="inline-block"><FontAwesomeIcon icon={faLoader} size={'1x'} fixedWidth spin={true} /></icon>
	{:else}
		Submit
	{/if}
</Button>
