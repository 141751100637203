<script lang="ts" context="module">
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	import type { FormPath } from 'sveltekit-superforms';

	// the form object
	type T = Record<string, unknown>;
	// the path/name of the field in the form object
	type U = unknown;
</script>

<script lang="ts" generics="T extends Record<string, unknown>, U extends FormPath<T>">
	import type { IconDefinition } from '@awesome.me/kit-065f90d5b1/icons';
	import { FontAwesomeIcon } from '@fortawesome/svelte-fontawesome';
	import { faCheckCircle, faXmarkCircle, faDiamondExclamation } from '@awesome.me/kit-065f90d5b1/icons/sharp-duotone/solid';
	import { twMerge } from 'tailwind-merge';
	import * as AlertDialog from '$lib/components/ui/alert-dialog';

	export let message: App.Superforms.Message;

	type MessageType = App.Superforms.Message['type'];
	type El = 'content' | 'wrapper' | 'icon' | 'title' | 'body' | 'action';
	type ClassState = 'base' | MessageType;
	type ClassStates = Record<ClassState, string>;
	type Icons = Record<MessageType, IconDefinition>;

	const icons: Icons = {
		success: faCheckCircle,
		error: faXmarkCircle,
		failure: faDiamondExclamation
	};

	let classes: Record<El, ClassStates> = {
		content: {
			base: 'bg-white',
			success: '',
			error: '',
			failure: ''
		},
		wrapper: {
			base: 'text-center py-5 flex flex-col justify-center gap-5',
			success: '',
			error: '',
			failure: ''
		},
		icon: {
			base: '',
			success: 'text-green-500',
			error: 'text-red-500',
			failure: 'text-orange-500'
		},
		title: {
			base: 'text-4xl text-tee-dark-additional1 font-bold flex gap-4 justify-center items-center',
			success: '',
			error: '',
			failure: ''
		},
		body: {
			base: 'text-tee-dark-additional1 max-w-lg mx-auto text-lg leading-8',
			success: '',
			error: '',
			failure: ''
		},
		action: {
			base: 'bg-blue-500 text-white py-2 px-4 rounded',
			success: '',
			error: '',
			failure: ''
		}
	};

	let type: MessageType;
	let title: string;
	let body: string;
	let icon: IconDefinition;
	let contentClass = classes.content.base;
	let wrapperClass = classes.wrapper.base;
	let iconClass = classes.icon.base;
	let titleClass = classes.title.base;
	let bodyClass = classes.body.base;
	let actionClass = classes.action.base;

	if (message) {
		type = message.type;
		icon = icons[type];

		if (message.detail) {
			title = message.detail.title;
			body = message.detail.body;
		}

		contentClass = twMerge(classes.content.base, classes.content[type]);
		wrapperClass = twMerge(classes.wrapper.base, classes.wrapper[type]);
		iconClass = twMerge(classes.icon.base, classes.icon[type]);

		titleClass = twMerge(classes.title.base, classes.title[type]);
		bodyClass = twMerge(classes.body.base, classes.body[type]);
		actionClass = twMerge(classes.action.base, classes.action[type]);
	}
	// console.log('ResponseMessage message', message);
	if (message?.data) {
		console.error('ResponseMessage data', message.data);
	}
</script>

{#if message?.detail}
	<AlertDialog.Root open>
		<AlertDialog.Content class={contentClass}>
			<AlertDialog.Header class={wrapperClass}>
				<AlertDialog.Title class={titleClass}>
					<FontAwesomeIcon {icon} class={iconClass} size="1x" />
					{title}
				</AlertDialog.Title>
				<!-- eslint-disable-next-line svelte/no-at-html-tags -->
				<AlertDialog.Description class={bodyClass}>{@html body}</AlertDialog.Description>
			</AlertDialog.Header>
			<AlertDialog.Footer class="sm:justify-center">
				<AlertDialog.Action class={actionClass}>Continue</AlertDialog.Action>
			</AlertDialog.Footer>
		</AlertDialog.Content>
	</AlertDialog.Root>
{/if}
