<script lang="ts" context="module">
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	import { type FormPath } from 'sveltekit-superforms';

	// the form object
	type T = Record<string, unknown>;
	// the path/name of the field in the form object
	type U = unknown;
</script>

<script lang="ts" generics="T extends Record<string, unknown>, U extends FormPath<T>">
	import { Label } from 'formsnap';
	import type { SuperForm } from 'sveltekit-superforms';
	import { Field, type FieldType } from '$lib/components/forms/v2';
	import type { Action } from 'svelte/action';

	export let form: SuperForm<T>;
	export let name: U;
	export let value: FormPath<T> | unknown;
	export let hide = false;
	export let label: string | null = null;
	export let type: FieldType = 'text';
	export let use: Action = () => void 0;
	export let description: string | undefined = undefined;
</script>

<Field {type} {form} {name} {hide} {description} let:fieldClasses let:attrs>
	<div class="relative flex items-center">
		{#if label}
			<Label class={fieldClasses.label}>{label}</Label>
		{/if}
		<slot name="left" />
		{#if type == 'color'}
			<input use:use type="color" {...attrs} class={fieldClasses.field} bind:value {...$$restProps} />
		{:else if type == 'date'}
			<input use:use type="date" {...attrs} class={fieldClasses.field} bind:value {...$$restProps} />
		{:else if type == 'datetime-local'}
			<input use:use type="datetime-local" {...attrs} class={fieldClasses.field} bind:value {...$$restProps} />
		{:else if type == 'email'}
			<input use:use type="email" {...attrs} class={fieldClasses.field} bind:value {...$$restProps} />
		{:else if type == 'file'}
			<input use:use type="file" {...attrs} class={fieldClasses.field} bind:value {...$$restProps} />
		{:else if type == 'hidden'}
			<input use:use type="hidden" {...attrs} class={fieldClasses.field} bind:value {...$$restProps} />
		{:else if type == 'month'}
			<input use:use type="month" {...attrs} class={fieldClasses.field} bind:value {...$$restProps} />
		{:else if type == 'number'}
			<input use:use type="number" {...attrs} class={fieldClasses.field} bind:value {...$$restProps} />
		{:else if type == 'password'}
			<input use:use type="password" {...attrs} class={fieldClasses.field} bind:value {...$$restProps} />
		{:else if type == 'range'}
			<input use:use type="range" {...attrs} class={fieldClasses.field} bind:value {...$$restProps} />
		{:else if type == 'search'}
			<input use:use type="search" {...attrs} class={fieldClasses.field} bind:value {...$$restProps} />
		{:else if type == 'tel'}
			<input use:use type="tel" {...attrs} class={fieldClasses.field} bind:value {...$$restProps} />
		{:else if type == 'time'}
			<input use:use type="time" {...attrs} class={fieldClasses.field} bind:value {...$$restProps} />
		{:else if type == 'url'}
			<input use:use type="url" {...attrs} class={fieldClasses.field} bind:value {...$$restProps} />
		{:else if type == 'week'}
			<input use:use type="week" {...attrs} class={fieldClasses.field} bind:value {...$$restProps} />
		{:else}
			<input use:use type="text" {...attrs} class={fieldClasses.field} bind:value {...$$restProps} />
		{/if}
		<slot name="right" />
	</div>
</Field>
