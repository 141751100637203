<script lang="ts">
	import { FontAwesomeIcon } from '@fortawesome/svelte-fontawesome';
	import { faCircleInfo } from '@awesome.me/kit-065f90d5b1/icons/sharp/regular';
	import { Description } from 'formsnap';

	export let description: string | undefined = undefined;
</script>

{#if description}
	<Description class="ml-4 flex items-center space-x-1">
		<FontAwesomeIcon icon={faCircleInfo} class="text-xs text-gray-500" />
		<small class="italic text-gray-500">{description}</small>
	</Description>
{/if}
